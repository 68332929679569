<template>
  <tr style="padding: 0px;">
    <td style="text-align: center;padding: 0px;"> {{ rowdata.idx + 1}} </td>

    <td  style="padding: 0px;width: 60px;">
      <input type="number" class="form-control" v-model="rowdata.fabric.id" @keyup.enter="loadFabric(rowdata.fabric.id)" />
    </td>
    <td style="padding: 0px;">
      <button class="btn btn-outline-success" @click="loadFabric(rowdata.fabric.id)" tabindex="-1" >Find</button>
    </td>
    <td style="padding: 0px;">
      {{rowdata.fabric.quality.name}}
    </td>
    <td style="padding: 0px;">
      {{rowdata.fabric.color.name}}
    </td>
    <td style="padding: 0px;text-align: right;padding-right: 20px;">
      {{rowdata.fabric.fab_weight | weightFormat}}
    </td>
    <td style="padding: 0px;text-align: right;width: 60px;">
      <input type="number" class="form-control text-right" v-if="rowdata" v-model="rowdata.needle_damages" @change="showmsg(6)" />
    </td>
    <td style="padding: 0px;text-align: right;width: 60px;">
      <input type="number" class="form-control text-right" v-if="rowdata" v-model="rowdata.hole_damages" @change="showmsg(7)"/>
    </td>
    <td style="padding: 0px;text-align: right;width: 60px;">
      <input type="number" class="form-control text-right" v-if="rowdata" v-model="rowdata.other_damages" @change="showmsg(8)"/>
    </td>
    <td style="padding: 0px;text-align: right">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(Math.abs(index))" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>
  </tr>
</template>

<script>
import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
export default {
  name: 'KnittingQcDetailRow',
  props: {
    myrow: {
      type: Object,
      default: () => JSON.parse('{"idx":0,"id":0,"master_id":0,"fabric_id":0,"item_id":0,"needle_damages":0,"hole_damages":0,"other_damages":0,"weight":0,"fabric":{"id":0,"status":"Active","type":"Knitting","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"stentering_id":0,"stentering_damages":0,"stentering_qc_id":0,"stentering_qc_damages":"0.000000","roll_id":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"qc_id":1,"shift":{"id":1,"status":"Active","name":"Day"},"machine":{"id":1,"name":""},"employee":{"id":1,"code":"","status":"Active","name":""},"item":{"id":0,"status":"Active","type":"KnittedFabrics","name":"","group_id":1,"unit_id":3,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"size":"","group":{"id":1,"name":""},"unit":{"id":3,"name":"Mtr","digits":3},"quality":{"id":1,"name":"","weight":0,"rate_based_on":1},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"rate_based_on":1},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0}}}')
    },
    myindex: {
      type: Number,
      default: () => 0,
    }
  },
  beforeMount () {
    const self = this;

    self.rowdata = this.myrow;
    self.index = this.myindex;
    self.rowdata.idx = this.myindex;
  },
  data () {
    return {
      index: 0,
      rowdata : JSON.parse('{"idx":0,"id":0,"master_id":0,"fabric_id":0,"item_id":0,"needle_damages":0,"hole_damages":0,"other_damages":0,"weight":0,"fabric":{"id":0,"status":"Active","type":"Knitting","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"stentering_id":0,"stentering_damages":0,"stentering_qc_id":0,"stentering_qc_damages":"0.000000","roll_id":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"qc_id":1,"shift":{"id":1,"status":"Active","name":"Day"},"machine":{"id":1,"name":""},"employee":{"id":1,"code":"","status":"Active","name":""},"item":{"id":0,"status":"Active","type":"KnittedFabrics","name":"","group_id":1,"unit_id":3,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"size":"","group":{"id":1,"name":""},"unit":{"id":3,"name":"Mtr","digits":3},"quality":{"id":1,"name":"","weight":0,"rate_based_on":1},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"rate_based_on":1},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0}}}')
    }
  },
  filters:{
    weightFormat (val){
      try{
        if(_.isNumber(val)){
          return val.toFixed(3);
        }else {
          return val;
        }

      }catch (e) {
        console.log(e);
      }

    }
  },
  methods:{
    removeRow(){
      this.$emit('onRemoveRow', (this.$data.rowdata.idx ) );
    },
    rowValueEqualizer(idx) {
      try {
        const self = this;

        let rowdata = this.$data.rowdata;

        switch (idx) {
          case 1: //Fab Code

            break;
          case 2: //Item
            rowdata.fabric.weight = this.$data.mapItems.get(rowdata.fabric.item.id).wgt.toFixed(3);
            rowdata.fabric.gst_rate = this.$data.mapItems.get(rowdata.fabric.item.id).itmgrp.gst;
            rowdata.fabric.item.group.gstrate = this.$data.mapItems.get(rowdata.fabric.item.id).itmgrp.gst;
            break;
        }
      }catch (e) {
        alert(e);
      }
    },
    loadFabric(id){
      if(id < 1) {
        return;
      }

      $('tr').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.rowdata = JSON.parse('{"idx":0,"id":0,"master_id":0,"fabric_id":0,"item_id":0,"needle_damages":0,"hole_damages":0,"other_damages":0,"weight":0,"fabric":{"id":0,"status":"Active","type":"Knitting","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"stentering_id":0,"stentering_damages":0,"stentering_qc_id":0,"stentering_qc_damages":"0.000000","roll_id":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"qc_id":1,"shift":{"id":1,"status":"Active","name":"Day"},"machine":{"id":1,"name":""},"employee":{"id":1,"code":"","status":"Active","name":""},"item":{"id":0,"status":"Active","type":"KnittedFabrics","name":"","group_id":1,"unit_id":3,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"size":"","group":{"id":1,"name":""},"unit":{"id":3,"name":"Mtr","digits":3},"quality":{"id":1,"name":"","weight":0,"rate_based_on":1},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"rate_based_on":1},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0}}}');
      self.$data.rowdata.idx = self.myindex ;


      fetch(`${process.env.VUE_APP_ROOT_API}api/knitting/receipt/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {

          if(!_.isNull(resp.data)){
            let mdata = JSON.parse('{"idx":0,"id":0,"master_id":0,"fabric_id":0,"item_id":0,"needle_damages":0,"hole_damages":0,"other_damages":0,"weight":0,"fabric":{"id":0,"status":"Active","type":"Knitting","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"stentering_id":0,"stentering_damages":0,"stentering_qc_id":0,"stentering_qc_damages":"0.000000","roll_id":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"qc_id":1,"shift":{"id":1,"status":"Active","name":"Day"},"machine":{"id":1,"name":""},"employee":{"id":1,"code":"","status":"Active","name":""},"item":{"id":0,"status":"Active","type":"KnittedFabrics","name":"","group_id":1,"unit_id":3,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"size":"","group":{"id":1,"name":""},"unit":{"id":3,"name":"Mtr","digits":3},"quality":{"id":1,"name":"","weight":0,"rate_based_on":1},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"rate_based_on":1},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0}}}')
            mdata.fabric  = resp.data;

            self.$data.rowdata = mdata;
            self.$data.rowdata.idx = self.myindex;
            self.$data.rowdata.fabric_id = resp.data.id;  //Assign the Fabric ID
            self.$data.rowdata.item_id = resp.data.item_id;  //Assign the Fabric Item ID
            self.showmsg(1);

          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('tr').unblock();
      });
    },
    showmsg(colIndex){

      switch (colIndex) {
        case 1:
          this.$emit('onrow_change', this.index, colIndex, this.rowdata);
          break;
        case 6:
          this.$emit('onrow_change', this.index, colIndex, this.rowdata.needle_damages);
          break;
        case 7:
          this.$emit('onrow_change', this.index, colIndex, this.rowdata.hole_damages);
          break;
        case 8:
          this.$emit('onrow_change', this.index, colIndex, this.rowdata.other_damages);
          break;
      }

      //alert(this.rowdata.needle_damages);
    },
  }
}
</script>

<style scoped>
input:focus { background: #feff00; }
textarea:focus { background: #feff00; }
select:focus { background: #feff00; }
button:focus { background: #feff00; }
</style>
